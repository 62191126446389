/**
 * @name: 系统管理-挂账处理权限配置接口文件
 * @author: itmobai
 * @date: 2023-08-04 17:46
 * @description： 系统管理-挂账处理权限配置接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import type { IHang, IHangParam } from "./types";

/**
 * 挂账账号列表查询
 * @param param
 * @returns
 */
export const hangingUserPowerQueryApi = (param: IHangParam) => get<IHang[]>("/admin/hangingUserPower/query", param)
/**
 * 挂账账号修改状态
 * @param data
 * @returns
 */
export const hangingUserPowerModifyApi = (data: IHang) => postJ("/admin/hangingUserPower/modify", data)

/**
 * 挂账账号创建
 * @param data
 * @returns
 */
export const hangingUserPowerCreateApi = (data: IHang) => postJ("/admin/hangingUserPower/create", data)
