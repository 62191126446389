
/**
 * @name: 系统管理-挂账处理权限配置
 * @author: itmobai
 * @date: 2023-08-04 17:53
 * @description： 系统管理-挂账处理权限配置
 * @update: 2023-08-04 17:53
 */
import { Vue, Component } from "vue-property-decorator"
import { hangingUserPowerQueryApi, hangingUserPowerModifyApi, hangingUserPowerCreateApi } from "@/apis/systemManage/hang"
import { systemAdminDropDownApi } from "@/apis/systemManage/account"
import type { IHang, IHangParam } from "@/apis/systemManage/hang/types"
import type { ICrudOption } from "@/types/m-ui-crud"

@Component({})
export default class systemManageHang extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IHang[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IHangParam = {}
  // 表单参数
  modelForm: Partial<IHang> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    addTitle: '新增处理账号',
    menu: false,
    dialogWidth: '600px',
    column: [
      {
        label: "序号",
        type: "index",
        width: 50,
        align: "center",
        addHide: true
      },
      {
        label: "类型",
        prop: "types",
        type: "select",
        search: true,
        align: "center",
        dicData: [
          {
            label: '会计',
            value: 1
          },
          {
            label: '出纳',
            value: 2
          },
          {
            label: '管理员',
            value: 3
          }
        ],
        rules: [
          {required: true, message: "请选择类型", trigger: "blur"}
        ]
      },
      {
        label: "账号",
        prop: "account",
        align: "center",
        search: true,
        addHide: true,
      },
      {
        label: "账号",
        prop: "systemAdminId",
        type: "select",
        dicData: [],
        hide: true,
        rules: [
          {required: true, message: "请选择账号", trigger: "blur"}
        ]
      },
      {
        label: "备注",
        prop: "remarks",
        overHidden: true,
        align: "center",
        type: "textarea",
        maxlength: 30,
        showWordLimit: true
      },
      {
        label: "状态",
        prop: "status",
        width: 150,
        align: "center",
        slot: true,
        addHide: true
      }
    ]
  }

  openAdd () {
    // @ts-ignore
    this.$refs.crudRef.rowAdd();
    this.getAccountList()
  }

  getAccountList () {
    systemAdminDropDownApi().then(e => {
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn('systemAdminId', {
        dicData: e.map(item => {
          return {
            label: `${item.realName}(${item.phone})`,
            value: item.id
          }
        })
      })
    })
  }

  rowSave (form: IHang, done: Function, loading: Function) {
    hangingUserPowerCreateApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  getList () {
    this.tableLoading = true
    hangingUserPowerQueryApi(this.queryParam).then(e => {
      this.tableData = e
      this.tableLoading = false
    })
  }

  statusChange (id: string, status: number) {
    hangingUserPowerModifyApi({id, status} as IHang).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  created () {
    this.getList()
  }
}
